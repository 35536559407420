<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="dataForm"
      :rules="rules"
    >
      <a-form-model-item label="应用名称" prop="appType">
        <!-- <a-select v-model="dataForm.appType" placeholder="请选择" allow-clear>
          <a-select-option v-for="(item,key) in [{name:'安卓',id:0},{name:'IOS',id:1},{name:'固件',id:2}]" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select> -->
        <a-radio-group button-style="solid" v-model="dataForm.appType">
          <a-radio-button :value="0">安卓</a-radio-button>
          <a-radio-button :value="1">IOS</a-radio-button>
          <a-radio-button :value="2">固件</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="更新说明" prop="upNotes">
        <a-input v-model="dataForm.upNotes" placeholder="请输入" type="textarea"></a-input>
      </a-form-model-item>
      <a-form-model-item label="文件" prop="downloadUrl">
        <a-upload
          action="http://47.245.94.137:7070/admin-api/common/upload"
          :headers="header"
          class="upload-demo"
          ref="upload"
          @change="handleChange"
          :accept="dataForm.appType===0?'.APK':(dataForm.appType===2?'.bin':'.ipa') "
          :showUploadList="false"
        >
          <a-button :loading="uploadLoading"> <a-icon type="upload" /> 选取文件 </a-button>
          <span v-if="fileName">{{ fileName }}</span>
          <span v-show="false">{{ dataForm.downloadUrl }}</span>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item :label="dataForm.appType==2 ? '固件版本号名称' : '版本号名称(1.0.0)'" prop="versionName">
        <a-input
          v-model="dataForm.versionName"
          placeholder="请输入"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item
        :label="dataForm.appType==2 ? '固件版本号' : '版本标识号(100)'"
        prop="versionCode"
      >
        <a-input
          v-model="dataForm.versionCode"
          placeholder="请输入"
        ></a-input>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import store from '@/store'
import { addappVersion } from '@/api/system/version'
import { upload } from '@/api/pages/upload'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      header: { Authorization: 'Bearer ' + store.getters.access_token },
      submitLoading: false,
      formTitle: '',
      fileName: '',
      uploadLoading: false,
      // 表单参数
      dataForm: {
        appType: 0,
        compulsoryUp: 0,
        downloadUrl: '',
        id: 0,
        planUpTime: 0,
        planUpType: 0,
        remark: '',
        upHeSize: 0,
        upNotes: '',
        upType: 0,
        upUrl: '',
        upUrlType: 0,
        versionCode: '',
        versionName: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        appType: [
          { required: true, message: '类型不能为空', trigger: 'blur' }
        ],
        downloadUrl: [
          { required: true, message: '请上传文件', trigger: 'blur' }
        ],
        upNotes: [
          { required: true, message: '更新说明不能为空', trigger: 'blur' }
        ],
        versionName: [
          { required: true, message: '版本号名称不能为空', trigger: 'blur' }
        ],
        versionCode: [
          { required: true, message: '版本号不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    console.log(upload)
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 上传成功
    handleChange (info) {
      console.log(info.file.status, info.file)
      if (info.file.status !== 'uploading') {
        this.uploadLoading = true
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        console.log(info.file.status, info.file.response.data, info.file)
        this.fileName = info.file.name
        this.dataForm.downloadUrl = info.file.response.data
        this.$message.success(`${info.file.name} 上传成功`)
        this.uploadLoading = false
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
        this.uploadLoading = false
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fileName = ''
      this.dataForm = {
        appType: 0,
        compulsoryUp: 0,
        downloadUrl: '',
        id: 0,
        planUpTime: 0,
        planUpType: 0,
        remark: '',
        upHeSize: 0,
        upNotes: '',
        upType: 0,
        upUrl: '',
        upUrlType: 0,
        versionCode: '',
        versionName: ''
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          addappVersion(this.dataForm).then(response => {
            this.$message.success(
              '新增成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
